<template>
    <d-alert theme="primary" :show="isExteriorDone">
        <span>De exterieur studio is klaar, plaats nieuw voertuig</span>
    </d-alert>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'successExteriorDone',
    computed: {
        ...mapGetters('photoStudio.studio', ['isExteriorDone']),
    }
}
</script>
