<template>
    <d-alert theme="warning" class="banner" :show="!areStreamsConnected">
        <span>Stream word opgestart of werkt niet (duurt dit te lang? probeer de pagina te verversen)</span>
    </d-alert>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'warningStreamsNotConnected',
    computed: {
        ...mapGetters('photoStudio.studio', ['areStreamsConnected']),
    }
}
</script>
