<template>
    <div>
        <exteriorStatusBanners />
        <router-view/>
    </div>
</template>

<script>
import exteriorStatusBanners from './components/exteriorStatusBanners'
export default {
    name: 'studioIndex',
    title: 'Fotostudio',
    components: { exteriorStatusBanners }
}
</script>
