<template>
    <d-alert theme="info" :show="!!processEvent.type && !exteriorOpenDoors && !isExteriorDone">
        <span>Voertuig: ({{ processEvent.licensePlate | licensePlate }})</span>
        <span>{{ eventStatusMessage }}</span>
    </d-alert>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'infoCurrentProcess',
    computed: {
        ...mapGetters('photoStudio.studio', ['processEvent', 'exteriorOpenDoors', 'isExteriorDone']),
        eventStatusMessage() {
            return ` Status: deuren ${this.processEvent.type === 'open' ? 'open' : 'gesloten'} (${this.processEvent.message} / 18)`
        }
    }
}
</script>
