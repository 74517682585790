<template>
    <div>
        <warningStreamsNotConnected />
        <warningDoorsMustBeenOpened />
        <infoCurrentProcess />
        <successExteriorDone />
    </div>
</template>

<script>
import infoCurrentProcess from './banners/infoCurrentProcess'
import successExteriorDone from './banners/successExteriorDone'
import warningDoorsMustBeenOpened from './banners/warningDoorsMustBeenOpened'
import warningStreamsNotConnected from './banners/warningStreamsNotConnected'

export default {
    name: 'ExteriorStatusBanners',
    components: {
        infoCurrentProcess,
        successExteriorDone,
        warningDoorsMustBeenOpened,
        warningStreamsNotConnected,
    },
    created() {
        this.setup()
    },
    beforeDestroy() {
        this.$store.dispatch('photoStudio.studio/closeStreams')
    },
    methods: {
        async setup() {
            await this.$store.dispatch('photoStudio.studio/setupStreams')
        }
    }
}
</script>

<style scoped lang="scss">
.banner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        float: right;
    }
}
</style>
