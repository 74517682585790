<template>
    <d-alert theme="warning" class="banner" :show="exteriorOpenDoors">
        <span>Deuren moeten geopend worden</span>
        <button class="btn btn-secondary" @click="doorsOpened">deur geopend</button>
    </d-alert>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: 'warningDoorsMustBeenOpened',
    computed: {
        ...mapGetters('photoStudio.studio', ['exteriorOpenDoors']),
    },
    methods: {
        async doorsOpened() {
            await this.$store.dispatch('photoStudio.studio/finishProcess')
        }
    }
}
</script>
